// export const url = "https://mozakcrm.herokuapp.com/api/v1"
// export const url = "https://api.mozack.co/api/v1"
// export const url = "http://localhost:8000/api/v1"


export const baseurl = "https://api.mozack.co"
// export const baseurl = "https://test.mozack.co";
// export const baseurl = "https://testapi.zidham.com";
// export const baseurl = "http://localhost:8000"
// export const baseurl = "http://192.168.4.105:8000";
export const url = `${baseurl}/api/v1`;
export const socketbaseurl = "https://notifications.mozack.co/";

export const OTPtimer = 300;
export const appBuildVersion = "1.0.5";

