import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { url } from "../../baseUrl";
import DatatablePage from "../../Components/DataTable/DataTable";
import CustomizedSnackbars from "../../Components/SnackBar/SnackBar";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CreateIcon from "@mui/icons-material/Create";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import RefreshIcon from "@mui/icons-material/Refresh";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  darkButton,
  lightBackground,
  lightText,
  white,
} from "../../Assets/Theme/ThemeColors";
import { useDispatch, useSelector } from "react-redux";
import BasicDatePicker from "../../Components/DatePicker/DatePicker";
import { userUnAuthorized } from "../../redux/actions/commonAction";

const RawDataList = () => {
  const [data, setData] = useState();

  const [startDate, setStartDate] = useState(
    new Date(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-2`)
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 30)
  );

  const [isSheetLoaded, setIsSheetLoaded] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.user.data);
  const userType = state.data.user.user_type;
  console.log(state.data.user.user_type);
  // const navigate = useNavigate();

  const columns = [
    {
      label: "Month",
      field: "month",
      sort: "asc",
      width: 150,
    },
    {
      label: "Date",
      field: "date",
      sort: "asc",
      width: 270,
    },
    {
      label: "Client Name",
      field: "client_name",
      sort: "asc",
      width: 200,
    },
    {
      label: "Email Address",
      field: "email",
      sort: "asc",
      width: 100,
    },
    {
      label: "Phone Number",
      field: "phone_number",
      sort: "asc",
      width: 150,
    },
    {
      label: "Keyword",
      field: "keyword",
      sort: "asc",
      width: 100,
    },

    {
      label: "Sign up / Chat",
      field: "lead_status",
      sort: "asc",
      width: 100,
    },
    {
      label: "Paid / Unpaid",
      field: "payment_status",
      sort: "asc",
      width: 100,
    },
    {
      label: "Calling Comments",
      field: "calling_comment",
      sort: "asc",
      width: 100,
    },
  ];

  useEffect(() => {
    getAllRawData();
  }, []);

  const getFilteredAllRawData = async () => {
    try {
      setIsLoaded(true);
      let obj = {
        startDate,
        endDate,
      };
      const response = await axios
        .post(`${url}/rawData/filterRawByDate`, obj, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then((data) => {
          setData({
            columns,
            rows: data.data.data.data.map((item) => ({
              ...item,
              date: item.date.split("T")[0],
            })),
          });
          setIsOpen(true);
          setMessage("Data Loaded!");
          setSeverity("success");
          setIsLoaded(false);
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            console.log("unauthorized error");
            userUnAuthorized(dispatch);
          } else {
            console.log("network error");
            setIsLoaded(false);
            setIsOpen(true);
            setMessage(error.message);
            setSeverity("error");
          }
        });
      
    } catch (error) {
      console.log("error", error.message);
      setIsLoaded(false);
      setIsOpen(true);
      setMessage(error.message);
      setSeverity("error");
    }
    setTimeout(() => {
      setIsOpen(false);
    }, 2000);
  };
  const getAllRawData = async () => {
    try {
      setIsLoaded(true);
      const response = await axios
        .get(`${url}/rawData`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then((data) => {
          setData({
            columns,
            rows: data.data.data.data.map((item) => ({
              ...item,
              date: item.date.split("T")[0],
            })),
          });
          setIsOpen(true);
          setMessage("Data Loaded!");
          setSeverity("success");
          setIsLoaded(false);
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            console.log("unauthorized error");
            userUnAuthorized(dispatch);
          } else {
            console.log("network error");
            setIsLoaded(false);
            setIsOpen(true);
            setMessage(error.message);
            setSeverity("error");
          }
        });
      
    } catch (error) {
      console.log("error", error.message);
      setIsLoaded(false);
      setIsOpen(true);
      setMessage(error.message);
      setSeverity("error");
    }
    setTimeout(() => {
      setIsOpen(false);
    }, 2000);
  };

  return (
    <>
      {" "}
      <Container
        style={{
          maxWidth: "100%",
          padding: "0px",
          overflowY: "scroll",
          height: "90vh",
        }}
      >
        <Paper fullWidth sx={{ m: 1, p: 2, maxWidth: "100%" }}>
          <Grid style={{ display: "flex", justifyContent: "flex-start" }}>
            <Typography variant="h5" color={`${lightText} `}>
              Raw Data
            </Typography>
          </Grid>

          <Grid
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "40px",
              paddingBottom: "20px",
            }}
          >
            <Grid container md={6} xs={12}>
              <Grid container item md={3} xs={12} sx={{ p: 1 }}>
                <BasicDatePicker
                  label={"Start Date"}
                  value={startDate}
                  setValue={setStartDate}
                  item
                />
              </Grid>
              <Grid container item md={3} xs={12} sx={{ p: 1 }}>
                <BasicDatePicker
                  label={"End Date"}
                  value={endDate}
                  setValue={setEndDate}
                  item
                />
              </Grid>
              <Grid container item md={3} xs={12} sx={{ p: 3 }}>
                <Button
                  item
                  onClick={getFilteredAllRawData}
                  variant="contained"
                  fullWidth
                  style={{
                    background: `${lightBackground} `,
                    color: `${white} `,
                  }}
                >
                  {isLoaded ? (
                    <span>
                      {" "}
                      <CircularProgress
                        style={{
                          color: `${white}`,
                          height: "20px",
                          width: "20px",
                        }}
                      />
                    </span>
                  ) : (
                    <>
                      <RefreshIcon sx={{ mr: 1 }} />
                      Get Data
                    </>
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <DatatablePage data={data} isLoaded={isLoaded} />
        </Paper>
      </Container>
      <CustomizedSnackbars
        isOpen={isOpen}
        severity={severity}
        message={message}
      />
    </>
  );
};

export default RawDataList;
